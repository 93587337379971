import React from 'react';
import { FormattedMessage } from 'react-intl';

import bgImg from './img/header.jpg';

import './styles.scss';
import { defaultFormatValues } from '../../../data/defaultFormat';
import SalesButton from '../../SalesButton/SalesButton';
import LocalizedLink from '../../LocalizedLink/LocalizedLink';

const CardHeroSolution: React.FC = ({ children }) => (
  <section className="cajita-hero hero overflow-visible" style={{backgroundImage: `url(${bgImg})`}}>
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-7 order-2 order-lg-1">
          <h1 className="text-white display-header">
            <b>En <span>Kushki</span>, simplificamos tus pagos presenciales y en línea mediante una plataforma omnicanal.
              <br /> <br />
              Kushki siempre presente.
            </b>
          </h1>
          {/* <a href="#form">
            <div className="button-responsive-row button-header">
                <FormattedMessage id="buttons.sales"/>
            </div>
          </a> */}
          {/* <p style={{marginTop: "10pt", color: "#00EEB3"}}>*Disponible en México</p> */}
        </div>
        <div className="col-lg-5 order-1 order-lg-2 text-md-center text-lg-right">
          {/* <HeroImg className="img-fluid mb-layout-3 mb-lg-0"/> */}
        </div>
      </div>
    </div>

  </section>
);

export default CardHeroSolution;
