import * as React from 'react';
import IndexLayout from '../layouts';
import Footer from '../components/Footer/Footer';
import SEO from '../components/SEO/SEO';
import { LocaleTypes } from '../data/locales';
import CardHeroSolution from '../components/Features/Card/CardSolutionHero';
import '../components/Features/Card/styles.scss';
import axios from 'axios';

import bgImg from '../components/Features/Card/card/bg-form.jpg';

import icon1 from '../components/Features/Card/card/cards/icon1.svg';
import icon2 from '../components/Features/Card/card/cards/icon2.svg';
import icon3 from '../components/Features/Card/card/cards/icon3.svg';
import icon4 from '../components/Features/Card/card/cards/icon4.svg';
import icon5 from '../components/Features/Card/card/cards/icon5.svg';
import icon6 from '../components/Features/Card/card/cards/icon6.svg';
import icon7 from '../components/Features/Card/card/cards/icon7.svg';
import icon8 from '../components/Features/Card/card/cards/icon8.svg';

import payment1 from '../components/Features/Card/card/payment/1.svg';
import payment2 from '../components/Features/Card/card/payment/2.svg';
import payment3 from '../components/Features/Card/card/payment/3.svg';
import payment4 from '../components/Features/Card/card/payment/4.svg';
import payment5 from '../components/Features/Card/card/payment/5.svg';
import payment6 from '../components/Features/Card/card/payment/6.svg';
import payment7 from '../components/Features/Card/card/payment/7.svg';
import payment8 from '../components/Features/Card/card/payment/8.svg';
import payment9 from '../components/Features/Card/card/payment/9.svg';
import payment10 from '../components/Features/Card/card/payment/10.svg';

import apiImage from '../components/Features/Card/card/api.png';

import product1 from '../components/Features/Card/card/product/1.jpg';
import product2 from '../components/Features/Card/card/product/2.jpg';
import product3 from '../components/Features/Card/card/product/3.jpg';
import LocalizedLink from '../components/LocalizedLink/LocalizedLink';

import userForm from '../components/Features/Card/card/form/user-form.svg';
import emailForm from '../components/Features/Card/card/form/email-form.svg';
import laptopForm from '../components/Features/Card/card/form/laptop-form.svg';
import phoneForm from '../components/Features/Card/card/form/phone-form.svg';
import chevronDown from '../components/Features/Card/card/form/chevron-down.svg';


import VisaMaster from '../components/Features/Card/img/visa_master.png';
import Cert1 from '../components/Features/Card/card/payment2/cert1.jpg';
import Cert2 from '../components/Features/Card/card/payment2/cert2.jpg';

import smartlink from '../components/Features/Card/card/tabs/smartlink.png';
import apptoapp from '../components/Features/Card/card/tabs/apptoapp.png';
import eds from '../components/Features/Card/card/tabs/3ds.png';
import api from '../components/Features/Card/card/tabs/api.png';

interface CajitaPageProps {
  pathContext: {
    localeCode: LocaleTypes;
  };
  location: Location;
}

interface EbookTemplateProps {
  data: {
    contentfulEbook: {
      title: string;
      subtitle?: string;
      summary: string;
      slug: string;
      contentList: string[];
      author?: string;
      role?: string;
      campaign: string;
      ebookPdf: {
        localFile: {
          publicURL: string;
        }
      }
      authorQuote?: {
        authorQuote: string;
      }
      ebookImage: {
        localFile: {
          publicURL: string;
        }
      }
      authorImage?: {
        localFile: {
          publicURL: string;
        }
      },
      synopsis?: {
        childMarkdownRemark: {
          html: string;
        }
      }
    };
    allContentfulEbook: any
  };
  pathContext: {
    locale: LocaleTypes;
  };
  location: Location;
}

interface CustomWindow extends Window {
  dataLayer: any[]; // O el tipo específico que estés utilizando en tu dataLayer
}

declare let window: CustomWindow;


const CajitaPage: React.FC<CajitaPageProps> = ({ pathContext, location }) => {

  //if is mobile 
  const [isMobile, setIsMobile] = React.useState(false);

  React.useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    }
    window.addEventListener('resize', () => {
      if (window.innerWidth < 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    });
  }, []);

  //google tag manager
  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || [];
      // window.dataLayer.push({
      //   event: "Clic - Formulario",
      //   eventModel: { event_category: "Contacto Ventas", event_label: "Contacto Ventas - Formulario" },
      //   "gtm.uniqueEventId": "78" 
      // });
    }
  }, []);


  const [payments, setPayments] = React.useState([
    payment1,
    payment2,
    payment3,
    payment4,
    payment5,
    payment6,
    payment7,
    payment8,
    payment9,
    payment10,
  ]);

  const [solutions, setSolutions] = React.useState([
    {
      icon: icon1,
      title: "Solución multicanal",
      text: "Solución integral de pagos presentes y pagos en línea  para tu negocio <b>físico y/o digital.</b>"
    },
    {
      icon: icon2,
      title: "Ofrece meses sin interes",
      text: "Ofrece meses sin intereses (MSI) hasta 24 MSI* en tus ventas con tarjeta de crédito. <br/> <br/> *Aplica solo para México."
    },
    {
      icon: icon3,
      title: "Consola de Administración",
      text: "Administrar terminales y dispositivos, consultar movimientos y generar reportes en tiempo real."
    },
    {
      icon: icon4,
      title: "Acepta múltiples tarjetas",
      text: "En México, acepta todas las tarjetas, vales y contactless. En Chile, acepta tarjetas Visa y Mastercard nacionales e internacionales, wallets y contactless."
    },
    {
      icon: icon5,
      title: "Nos adaptamos a tu modelo de negocio",
      text: "Ofrecemos diferentes tipos de integraciones según tu necesidad."
    },
    {
      icon: icon6,
      title: "Splits de Pagos",
      text: "Flexibilidad de fraccionar pagos a tu medida con depósitos en una o varias cuentas bancarias."
    },
    {
      icon: icon7,
      title: "Transacciones seguras",
      text: "Ventas seguras con firma electrónica (PIN) y seguridad de los datos para comercios y clientes."
    },
    {
      icon: icon8,
      title: "Flexibilidad y adaptabilidad",
      text: "Crecemos contigo. Tasas de acuerdo al tipo y volumen transaccional de tu negocio."
    },

  ]);

  const [products, setProducts] = React.useState([
    {
      image: product1,
      title: "Terminal Nano",
      des: (
        <ul>
          <li>Banda magnética, Chip & PIN y NFC</li>
          <li>Conexión vía Bluetooth</li>
          <li>Pin pad</li>
          <li style={{color:"#1E65AE", fontStyle:"italic"}}>Disponible sólo en México </li>
        </ul>
      )
    },
    {
      image: product2,
      title: "Terminal Ultra",
      des: (
        <ul>
          <li>Smartphone, soporta llamadas y SMS con aceptación de tarjeta</li>
          <li>Sistema Android</li>
          <li>Todos los modos de entrada disponibles</li>
          <li>Permite descargar aplicaciones comerciales</li>
          <li>Datos de Internet incluidos</li>
          <li>Integración App to App</li>
          <li>Banda magnética, Chip & Pin y NFC</li>
          <li>Capacidad de escaneo directo de QR</li>
          <li style={{color:"#1E65AE", fontStyle:"italic"}}>Disponible sólo en México </li>
        </ul>
      )
    },
    {
      image: product3,
      title: "Terminal Ultra Print",
      des: (
        <ul>
          <li>Smart Pos con impresora</li>
          <li>Sistema Android</li>
          <li>Todos los modos de entrada disponibles</li>
          <li>Permite descargar aplicaciones comerciales</li>
          <li>Datos de Internet incluidos</li>
          <li>Integración App to App</li>
          <li>Banda magnética, Chip & PIN y NFC</li>
          <li>Capacidad de escaneo directo de QR</li>
          <li style={{color:"#1E65AE", fontStyle:"italic"}}>Disponible en México y Chile. </li>
        </ul>
      )
    },
  ]);

  const tabs = [
    {
      title: "API",
      content: (
        <><ul>
          <li>
            <p className="text-primary body1">
              Integración de tarjeta presente
            </p>
          </li>
          <li>
            <p className="text-primary body1">
              Solución 100% back-end
            </p>
          </li>
          <li>
            <p className="text-primary body1">
              Diseñado para comercios con recursos de TI y control de su sitio web
            </p>
          </li>
          <li>
            <p className="text-primary body1">
            Todas las soluciones de comercio electrónico están construidas sobre esta API
            </p>
          </li>
        </ul>
        </>
      ),
    },
    {
      title: "App to App",
      content: (
        <ul>
          <li>
            <p className="text-primary body1">
              Android/Harmony OS (Huawei)/IOS
            </p>
          </li>
          <li>
            <p className="text-primary body1">
              La forma más fácil de aceptar pagos con tarjeta.
            </p>
          </li>
          <li>
            <p className="text-primary body1">
              Actualizaciones remotas
            </p>
          </li>
          <li>
            <p className="text-primary body1">
              Disponemos de las interacciones con los lectores y la seguridad
            </p>
          </li>
        </ul>
      ),
    },
    {
      title: "Cloud Terminal API",
      content: (
        <ul>
          <li>
            <p className="text-primary body1">
            Android/IOS/Windows ERPs.
            </p>
          </li>
          <li>
            <p className="text-primary body1">
            Permite cobrar a tus clientes desde tu sistema sin necesidad de una aplicación adicional.
            </p>
          </li>
          <li>
            <p className="text-primary body1">
            Facilidad en la gestión de inventarios.
            </p>
          </li>
          <li>
            <p className="text-primary body1">
            Facilidad de integración sin desarrollo previo.
            </p>
          </li>
        </ul>
      ),
    },
    // {
    //   title: "Smart Link",
    //   content: (
    //     <ul>
    //         <li>
    //           <p className="text-primary body1">
    //             Soporte web y móvil
    //           </p>
    //         </li>
    //         <li>
    //           <p className="text-primary body1">
    //             Enlace global para todos los pagos
    //           </p>
    //         </li>
    //         <li>
    //           <p className="text-primary body1">
    //             Enlaces personalizables con descripción específica, monto, opciones de pago o vencimiento.
    //           </p>
    //         </li>
    //         <li>
    //           <p className="text-primary body1">
    //             Click para soporte de pago
    //           </p>
    //         </li>
    //         <li>
    //           <p className="text-primary body1">
    //             API disponibles para integraciones de terceros
    //           </p>
    //         </li>
    //         <li>
    //           <p className="text-primary body1">
    //             3DS
    //           </p>
    //         </li>
    //       </ul>
    //   ),
    // }
  ];


  const [actualProduct, setActualProduct] = React.useState(5);

  const [actualTab, setActualTab] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActualTab(newValue);
  };

  const [average, setAverage] = React.useState([
    {
      value: 25000,
      label: "$25,000"
    },
    {
      value: 50000,
      label: "$50,000"
    },
    {
      value: 100000,
      label: "$100,000"
    },
    {
      value: 200000,
      label: "$200,000"
    },
    {
      value: 500000,
      label: "$500,000"
    },
    {
      value: 1000000,
      label: "$1,000,000"
    },
    {
      value: 3000000,
      label: "$3,000,000"
    },
  ]);


  const [formContact, setFormContact] = React.useState({
    name: '',
    company_name: '',
    average: '',
    email: '',
    phone_number: '',
    website: '',
    country: 'mexico',
  });

  const [loadingForm, setLoadingForm] = React.useState(false);
  const [errorForm, setErrorForm] = React.useState("");
  const [sendingForm, setSendingForm] = React.useState<any>(false);

  const handleFormContact = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormContact({ ...formContact, [event.target.name]: event.target.value });
  };
  const handleFormContactSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setFormContact({ ...formContact, [event.target.name]: event.target.value });
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    setLoadingForm(true);
    setErrorForm("");

    //validate all fields
    if (formContact.name.length === 0) {
      setErrorForm("El campo nombre es requerido");
      setLoadingForm(false);
      return;
    }
    if (formContact.company_name.length === 0) {
      setErrorForm("El campo empresa es requerido");
      setLoadingForm(false);
      return;
    }
    if (formContact.email.length === 0) {
      setErrorForm("El campo email es requerido");
      setLoadingForm(false);
      return;
    }
    //validtae emial format regex
    const regexEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if (!regexEmail.test(formContact.email)) {
      setErrorForm("El campo email no es válido");
      setLoadingForm(false);
      return;
    }
    if(!validarEmail(formContact.email)){
      setErrorForm("El campo email no debe ser un correo personal");
      setLoadingForm(false);
      return;
    }
    if (formContact.phone_number.length < 6) {
      setErrorForm("El campo teléfono no es válido");
      setLoadingForm(false);
      return;
    }
    if (formContact.website.length < 2) {
      setErrorForm("El campo página web no es válido");
      setLoadingForm(false);
      return;
    }

    if(!validarURL(formContact.website)) {
      setErrorForm("El campo página web no es una url valida");
      setLoadingForm(false);
      return;
    }

    if (formContact.average === "") {
      setErrorForm("El campo promedio transaccional mensual es requerido");
      setLoadingForm(false);
      return;
    }

    if(formContact.country === "") {
      setErrorForm("El campo país es requerido");
      setLoadingForm(false);
      return;
    }

    console.log(formContact);
    axios.post('https://hooks.zapier.com/hooks/catch/1396001/3g8yx60/', formContact, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
      .then((response: any) => {
        gtag_report_conversion(location.href);

        setFormContact({
          name: '',
          company_name: '',
          average: '',
          email: '',
          phone_number: '',
          website: '',
          country: 'mexico',
        });

        setLoadingForm(false);
        setSendingForm(true);
        setTimeout(() => {
          sendingForm(false);
        }, 5000);
      }, (error: any) => {
        setLoadingForm(false);
        console.log(error);
      });
    event.preventDefault();
  }

  const gtag_report_conversion = (url: any) => {
    const callback = () => {
      if (typeof(url) !== 'undefined') {
        window.location = url;
      }
    };
  
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'conversion',
      send_to: 'AW-652396226/1yawCN3RutwDEMKNi7cC',
      event_callback: callback
    });
  
    return false;
  };

  function validarEmail(email: string): boolean {
    // Expresión regular para validar la estructura básica de un email
    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // Expresión regular para excluir dominios comunes de proveedores de correo electrónico personal
    const regexExcluirDominios = /^(?!.*@(gmail|hotmail|outlook|yahoo)\.com).*$/i;
    // Validar la estructura básica del email y excluir dominios comunes de proveedores de correo electrónico personal
    return regexEmail.test(email) && regexExcluirDominios.test(email);
}

function validarURL(url: string): boolean {
    var urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
    '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
  return !!urlPattern.test(url);
}


React.useEffect(() => {
  //get counrty param from url
  const urlParams = new URLSearchParams(window.location.search);
  const country = urlParams.get('country');
  if (country) {
    setFormContact({ ...formContact, country: country });
  }
}, []);
  


  const content = () => (
    <>
      <SEO titleId="cardsolution.metaTitle" descriptionId="cardsolution.metaDescription" />
      <CardHeroSolution />

      {/* ********** Form Lead **********  */}
        {/* Add a row with background #1E65AE and padding 40pt and in the center a div with a image and form in the right */}
        <div className="row" id='form'>
          <div className="col-12 text-center bg-form" style={{ background: "linear-gradient(-45deg, #1E65AE, #023365)"}}>
            <div className="row justify-content-center align-items-center box-form-box">
              <div className="col-12 col-md-6 text-left">
                <div className="width-100 margin-auto title-form">
                  <h2 className="text-white">
                    Con <span>Kushki</span>: Unifica, simplifica, vende y expande tu negocio.
                  </h2>
                  <br />
                  <p className="text-white">
                    <b>Solución de pagos presenciales disponible en México y Chile. Acepta múltiples tarjetas y maximiza tus ventas.</b>
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6 text-center box-global-form">
                <div className="width-100 margin-auto">
                  <h2 className="text-white">
                    Déjanos tus datos
                  </h2>
                  <br />
                  <form className="row justify-content-center align-items-center box-form" onSubmit={(e) => e.preventDefault()} >
                    <div className="col-12 col-md-10">
                      <div className="form-group">
                        <div className="ico">
                          <img src={userForm} alt="icon1" width={24} height={24} />
                        </div>
                        <input type="text" className="form-control" placeholder="Nombre" onChange={handleFormContact} name="name" value={formContact.name} />
                      </div>
                    </div>
                    <div className="col-12 col-md-10">
                      <div className="form-group">
                        <div className="ico">
                          <img src={userForm} alt="icon1" width={24} height={24} />
                        </div>
                        <input type="text" className="form-control" placeholder="Empresa" onChange={handleFormContact} name="company_name" value={formContact.company_name} />
                      </div>
                    </div>
                    <div className="col-12 col-md-10">
                      <div className="form-group">
                        <div className="ico">
                          <img src={chevronDown} alt="icon1" width={15} height={15} />
                        </div>
                        <select className="form-control" name="average" value={formContact.average} onChange={handleFormContactSelect}>
                          <option value="" disabled selected>¿Cuál es el promedio transaccional mensual de tu empresa?</option>
                          {
                            average.map((item, index) => (
                              <option key={index} value={item.value}>{item.label}</option>
                            ))
                          }
                        </select>
                      </div>
                    </div>
                    <div className="col-12 col-md-10">
                      <div className="form-group">
                        <div className="ico">
                          <img src={emailForm} alt="icon1" width={24} height={24} />
                        </div>
                        <input type="text" className="form-control" placeholder="Email" onChange={handleFormContact} name="email" value={formContact.email} />
                      </div>
                    </div>
                    <div className="col-12 col-md-10">
                      <div className="form-group">
                        <div className="ico">
                          <img src={phoneForm} alt="icon1" width={24} height={24} />
                        </div>
                        <input type="text" className="form-control" placeholder="Teléfono" onChange={handleFormContact} name="phone_number" value={formContact.phone_number} />
                      </div>
                    </div>
                    <div className="col-12 col-md-10">
                      <div className="form-group">
                        <div className="ico">
                          <img src={laptopForm} alt="icon1" width={24} height={24} />
                        </div>
                        <input type="text" className="form-control" placeholder="Página Web" onChange={handleFormContact} name="website" value={formContact.website} />
                      </div>
                    </div>
                    <div className="col-12 col-md-10">
                      <div className="form-group">
                        <div className="ico">
                          <img src={chevronDown} alt="icon1" width={15} height={15} />
                        </div>
                        {/* <input type="text" className="form-control" placeholder="Pais" onChange={handleFormContact} name="country" value={formContact.country} /> */}
                        <select className="form-control" name="country" value={formContact.country} onChange={handleFormContactSelect}>
                          <option value="mexico">México</option>
                          <option value="chile">Chile</option>
                        </select>
                      </div>
                    </div>
                    {
                      errorForm.length > 0 ? <div className="col-12 col-md-10">
                        <div className="alert alert-danger" role="alert">
                          {errorForm}
                        </div>
                      </div> : null
                    }{
                      sendingForm ? <div className="col-12 col-md-10">
                        <div className="alert alert-success" role="alert">
                          Formulario enviado correctamente
                        </div>
                      </div> : null
                    }
                    <div className="col-12 col-md-10">
                      {
                        loadingForm ? <button className="btn btn-lg btn-b-f" style={{ width: "100%" }}  disabled>
                        Enviando...
                        </button> : <button className="btn btn-lg btn-b-f " style={{ width: "100%" }}  onClick={(e: any) => handleSubmit(e)}>
                        Enviar
                      </button>
                      }
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

      <section className="container-fluid">
        {/* ********** Benefits **********  */}
        <div className="row justify-content-center align-items-center padding-50-10">
          <div className="col-12 col-md-10 text-center">
            <div className="width-100 margin-auto mb-5">
              <h1 className="text-dark-blue">
              Una solución de pagos presentes que genera <br /> grandes beneficios para tu empresa
              </h1>
              <br />
              <p className="text-grey p1">
              Además de soluciones de pagos en línea, <b>Kushki</b> te ofrece soluciones de pagos presenciales mediante una plataforma omnicanal y terminales de última generación.
              </p>
            </div>
            <div className="row justify-content-center align-items-center mt-5">
              <div className="col-12 col-md-4 padding-20 text-left">
                <div className="card background-light">
                  <div className="card-body">
                    <h4 className="text-medium-blue">
                    Terminales de fácil uso con servicio 24/7
                    </h4>
                    <br />
                    <p className="text-grey p2">
                    Terminales para grandes empresas en diferentes industrias.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 padding-20 text-left">
                <div className="card background-light">
                  <div className="card-body">
                    <h4 className="text-medium-blue">
                    Conexión sin límites y datos sin costo con Dispositivos Ultra
                    </h4>
                    <br />
                    <p className="text-grey p2">
                    Datos gratis para realizar todas las ventas (conectividad constante).
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 padding-20 text-left">
                <div className="card background-light">
                  <div className="card-body">
                    <h4 className="text-medium-blue">
                    Adquirencia y trazabilidad de punta a punta
                    </h4>
                    <br />
                    <p className="text-grey p2">
                    Tenemos visibilidad completa de las transacciones con los emisores.
                    <br />
                    <br />
                      <img src={VisaMaster} alt="icon1" width={200}  />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        

        {/* ********** Solutions ********** */}
        <div className="row justify-content-center align-items-center padding-50-10">
          <div className="col-11 col-md-10 text-center">
            <div className="width-100 margin-auto mb-5">
              <h1 className="text-primary">
                Una solución que genera <br />beneficios significativos
              </h1>
            </div>
            <div className="row justify-content-center align-items-center mt-5">
              {solutions.map((item, index) => (
                <div className="col-12 col-md-3 padding-20" key={index}>
                  <div className="card background-light text-left" style={{ height: '200pt' }}>
                    <div className="card-body">
                      <img src={item.icon} alt="icon1" width={48} height={48} style={{ marginBottom: '10pt' }} />
                      <h5 className="text-dark p2">{item.title}</h5>
                      <br />
                      <p className="text-grey p3" dangerouslySetInnerHTML={{ __html: item.text }}></p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* ********** Payments ********** */}
        {/* <div className="row justify-content-center align-items-center padding-50-10" style={{ background: "#E2E8F0" }}>
          <div className="col-11 col-md-10 text-center">
            <div className="width-100 margin-auto">
              <h2 className="text-primary">
                Acepta todas las tarjetas, vales <br />y contactless en un solo lugar
              </h2>
            </div>
            <div className="row justify-content-center align-items-center col-10 m-auto mt-5">
              {payments.map((item, index) => (
                <div className="card-box" style={{ padding: '4pt' }} key={index}>
                  <div className="card-payment">
                    <img src={item} alt={`icon${item}`} width={80} height={80} />
                  </div>
                </div>
              ))}
            </div>
            <div className="width-100 margin-auto">
              <p className="text-primary p3 mt-2">
              *Aplica solo para México
              </p>
            </div>
          </div>
        </div> */}
        
        {/* ********** Products **********  */}
        <div className="row justify-content-center align-items-center padding-50-10" style={{ background: "#F7FAFC" }}>
          <div className="col-11 col-md-9 text-center">
            <div className="width-100 margin-auto">
              <h2 className="text-primary">
                Un dispositivo para <br /> cada necesidad
              </h2>
            </div>

            <div className="row justify-content-center align-items-baseline margin-top-20">
              {products.map((item, index) => (
                <div className="col-12 col-md-4 p-2" key={index}>
                  <div className="" style={{ height: '100%' }}>
                    <div className="card-body">
                      <div className="" style={{ height: '100%' }}>
                        <div>
                          <img src={item.image} alt="icon1" style={{width: "100%", margin: "0 auto"}} />
                        </div>
                        <div className="flex-1 pt-2 text-left justify-content-start">
                          <br /><br />
                          <h5 className="text-primary">{item.title}</h5>
                          {
                            actualProduct === index ? 
                            <button className="btn btn-lg btn-product mt-4" onClick={() => setActualProduct(5)}>
                            Ver menos
                          </button> : <button className="btn btn-lg btn-product mt-4" onClick={() => setActualProduct(index)}>
                            Conocer más
                          </button>
                          }
                        </div>
                        {
                          actualProduct === index ? <div className="flex-1 pt-2 animate__animated animate__fadeIn">
                          <p className='des-box'>
                            {item.des}
                          </p>
                        </div> : null
                        }
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* ********** Tabs ********** */}
        <div className="row justify-content-center align-items-center padding-50-10">
          <div className="col-12 col-md-10 text-center">
            <div className="width-100 margin-auto mb-3">
              <h2 className="text-primary">
                Soluciones de Integración
              </h2>
            </div>
            <br />
            <div className="d-flex justify-content-center align-items-center mb-4">
              <ul className="nav nav-pills flex-column flex-sm-row">
                {
                  tabs.map((item, index) => (
                    <div className={`flex-sm-fill text-sm-center nav-link ${actualTab === index ? "active" : ""}`} onClick={() => setActualTab(index)} key={index}> {item.title}</div>
                  ))
                }
              </ul>
            </div>
            <div className="d-flex justify-content-center align-items-center flex-column flex-sm-row col-8 col-sm-8 mb-4" style={{ margin: "40px auto 10px" }}>
              <div className="col-12 col-md-5 me-0 me-sm-3 mb-3 mb-sm-0">
                {
                  actualTab === 0 && <img src={api} alt="icon1" className="img-fluid" width={250} height={250} />
                }
                {
                  actualTab === 1 && <img src={apptoapp} alt="icon1" className="img-fluid" width={250} height={250} />
                }
                {
                  actualTab === 2 && <img src={eds} alt="icon1" className="img-fluid" width={250} height={250} />
                }
                {
                  actualTab === 3 && <img src={smartlink} alt="icon1" className="img-fluid" width={250} height={250} />
                }
              </div>
              <div className="flex-1 ps-0 ps-sm-3 text-left list-tabs p-2">
                {tabs.map((item, index) => (
                    <div key={index} style={{ display: actualTab === index ? "block" : "none" }}>
                      {item.content}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        {/* ********** Payments 2 ********** */}
        <div className="row justify-content-center align-items-center padding-50-10" style={{ background: "rgb(254 254 254)" }}>
          <div className="col-11 col-md-10 text-center">
            <div className="width-100 margin-auto">
              <h2 className="text-primary">
                Acepta todas las tarjetas, vales <br />y contactless en un solo lugar
              </h2>
            </div>
            <div className="row justify-content-center align-items-center col-10 m-auto mt-5 imagescert">
              <img src={Cert1} alt="icon1" />
              <img src={Cert2} alt="icon1" />
            </div>
            <div className="width-100 mt-4 margin-auto">
              <p className="text-primary p3 mt-2">
              *Aplica solo para México
              </p>
            </div>
          </div>
        </div>
        {/* ********** Banner Footer **********  */}
        <div className="row justify-content-center align-items-center padding-50-10" style={{ background: "#1E65AE", height: "50vh" }}>
          <div className="col-11 col-md-10 text-center">
            <div className="width-100 margin-auto">
              <h2 className="text-white">
                Con Kushki: Unifica, Simplifica, <br /> Vende y expande tu negocio.
              </h2>
              <br />
              <a href="#form">
                <button className="btn btn-lg btn-b-f">
                  Quiero comenzar con Kushki
                </button>
              </a>
            </div>
          </div>
        </div>


      </section>




      <Footer theme="primary" page={'cardsolution'} />
    </>
  );

  return <IndexLayout navbarTheme={isMobile ? "default" : "light"} render={content} locale={pathContext.localeCode} location={location} />
}

export default CajitaPage;
